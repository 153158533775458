
var tpj = jQuery;
var revapi1080;
tpj(document).ready(function() {
    if (tpj("#rev_slider_1080_1").revolution == undefined) {
        revslider_showDoubleJqueryError("#rev_slider_1080_1");
    } else {
        revapi1080 = tpj("#rev_slider_1080_1").show().revolution({
            sliderType: "carousel",
            jsFileLocation: "../../vendors/rev-slider/js",
            sliderLayout: "auto",
            dottedOverlay: "none",
            delay: 9000,
            navigation: {
                onHoverStop: "off",
            },
            carousel: {
                horizontal_align: "center",
                vertical_align: "center",
                fadeout: "on",
                vary_fade: "off",
                maxVisibleItems: 3,
                infinity: "on",
                space: 0,
                stretch: "off"
            },
            responsiveLevels: [1240, 1024, 778, 480],
            visibilityLevels: [1240, 1024, 778, 480],
            gridwidth: [1000, 800, 700, 480],
            gridheight: [800, 700, 600, 500],
            lazyType: "none",
            shadow: 0,
            spinner: "off",
            stopLoop: "on",
            stopAfterLoops: 0,
            stopAtSlide: 1,
            shuffle: "off",
            autoHeight: "off",
            disableProgressBar: "on",
            hideThumbsOnMobile: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
                simplifyAll: "off",
                nextSlideOnWindowFocus: "off",
                disableFocusListener: false,
            }
        });
    }
});

