<template>
  <Calendar msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Calendar from './components/GUI/Calendar.vue'

export default {
  name: 'App',
  components: {
    Calendar
  }
}
</script>


