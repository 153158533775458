import '../scss/plugins.scss';
import '../scss/style.scss';

import "./plugins/swiper";
import './plugins/preview-sucursales';
import './plugins/acerca-video';
import './revslider';
import './plugins/testimonial.js';
import './plugins/nuestraApp.js';
import '../vendors/polo-theme/functions';
import '../vendors/polo-theme/plugins';

import '../vendors/vue-calendar/js/app.b4b46d83.js'

//import './plugins/prensa.js';




//seccion productos, pagina conocenos
/** More at https://github.com/emoreno911/UI-to-Code **/
let $wrap = document.querySelector('.wrap'),
	$opts = document.querySelectorAll('.bar a');

Array.prototype.forEach.call($opts, (el, i) => {
	el.addEventListener('click', function (event) {
		$wrap.setAttribute('data-pos', i);			  
	});
});

let count = 0,
	$btns = document.querySelectorAll('.btn'),
	$cart = document.querySelector('.cart');

Array.prototype.forEach.call($btns, (el, i) => {
	el.addEventListener('click', function (event) {
		$cart.classList.add('add');
		$cart.querySelector('span').innerText = ++count;
		setTimeout(() => {
			$cart.classList.remove('add');
		}, 1500);			  
	});
});

//document.querySelector('.wrap-clip').style.borderTopWidth = window.innerHeight * .8 + 'px';

// sitio actual 2021
/* import '../vendors/ImageToContent/ImageToContent-main/src/js/index';
import '../vendors/ImageToContent/ImageToContent-main/src/js/preview';
import '../vendors/ImageToContent/ImageToContent-main/src/js/content';
import '../vendors/ImageToContent/ImageToContent-main/src/js/utils';
import '../vendors/ImageToContent/ImageToContent-main/src/js/textLinesReveal'; */


// import Dropdown from 'bootstrap/js/dist/dropdown';
// import Tab from 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/collapse';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';
// import Modal from 'bootstrap/js/dist/modal';
// import './js/plugins/swiper';

// // Menú //
// dropdownBootstrap('.dropdown-toggle');
// // Página precios
// tabsPrecios('#tabs-precios a');
// // Página Contacto
// tabsBootstrap('#tabs-contacto a[data-bs-target]');
// // Pagina Principal - Modal
// modalPromocion('#modal-promocion', '#modal-promocion .btn-close');
// // Barra de contacto
// offCanvasBootstrap(
//  '#barra-contacto',
//  '#button-offcanvas',
//  '#barra-contacto .btn-close'
// );
// // Instancias de Vue
// instancesVue(
//  '#app-clases-calendario',
//  '#app-clases-prueba',
//  '#landing-conekta'
// );
// // Modal - Clase Prueba
// modalClasePrueba(
//  '#btnClasePrueba',
//  '#floating-buttons-container a',
//  '#btnClasePrueba button[data-bs-dismiss]'
// );

// // Instancias Vue //
// function instancesVue(element1, element2, element3) {
//  if (document.querySelector(element1)) {
//   const calendario = document.querySelector(element1);
//   calendario.style.display = 'block';
//  }
//  if (document.querySelector(element2)) {
//   const calendarioPrueba = document.querySelector(element2);
//   calendarioPrueba.style.display = 'block';
//  }
//  if (document.querySelector(element3)) {
//   const landingConekta = document.querySelector(element3);
//   landingConekta.style.display = 'block';
//  }
// }
// // Tabs Bootstrap //
// function tabsBootstrap(element) {
//  var triggerTabList = document.querySelectorAll(element);
//  triggerTabList.forEach(function (triggerEl) {
//   var tabTrigger = new Tab(triggerEl);
//   triggerEl.addEventListener('click', function (event) {
//    event.preventDefault();
//    tabTrigger.show();
//   });
//  });
// }
// // Modal Bootstrap //
// function modalPromocion(element1, element2) {
//  if (document.querySelector(element1)) {
//   const elementModal = document.querySelector(element1);
//   const closeModal = document.querySelector(element2);
//   var modal = new Modal(elementModal, {
//    keyboard: false,
//    backdrop: 'static',
//    focus: true,
//   });
//   modal.show();
//   closeModal.addEventListener('click', function () {
//    modal.hide();
//   });
//  }
// }
// // Collapse Bootstrap //
// function offCanvasBootstrap(element1, element2, element3) {
//  const elementCanvas = document.querySelector(element1);
//  const openCanvas = document.querySelector(element2);
//  const closeCanvas = document.querySelector(element3);
//  var offCanvas = new Offcanvas(elementCanvas, {
//   backdrop: false,
//   scroll: true,
//  });
//  openCanvas.addEventListener('click', function (event) {
//   event.preventDefault();
//   offCanvas.show();
//  });
//  closeCanvas.addEventListener('click', function () {
//   offCanvas.hide();
//  });
// }
// // Dropdown Bootstrap //
// function dropdownBootstrap(element) {
//  var dropdownElementList = document.querySelectorAll(element);
//  dropdownElementList.forEach((triggerEl) => {
//   new Dropdown(triggerEl);
//  });
// }
// // Tabs Precios //
// function tabsPrecios(element) {
//  var triggerTabElements = document.querySelectorAll(element);
//  var tabTriggerList = [];
//  triggerTabElements.forEach(function (triggerEl) {
//   var tabTrigger = new Tab(triggerEl);
//   tabTriggerList.push(tabTrigger);
//  });
//  if (
//   location.hash == '#precios' ||
//   location.hash == '#bebes' ||
//   location.hash == '#ninos' ||
//   location.hash == '#adultos'
//  ) {
//   tabTriggerList[0].show();
//  } else if (location.hash == '#convenios') {
//   tabTriggerList[1].show();
//  } else if (location.hash == '#promociones') {
//   tabTriggerList[2].show();
//  }
//  window.addEventListener('hashchange', function () {
//   if (
//    location.hash == '#precios' ||
//    location.hash == '#bebes' ||
//    location.hash == '#ninos' ||
//    location.hash == '#adultos'
//   ) {
//    tabTriggerList[0].show();
//    window.location.href = window.location.href;
//   } else if (location.hash == '#convenios') {
//    tabTriggerList[1].show();
//    window.location.href = window.location.href;
//   } else if (location.hash == '#promociones') {
//    tabTriggerList[2].show();
//    window.location.href = window.location.href;
//   }
//  });
// }
// // Modal - Clase Prueba //
// function modalClasePrueba(element1, element2, element3) {
//  const elementModal = document.querySelector(element1);
//  const openModal = document.querySelector(element2);
//  const closeModal = document.querySelectorAll(element3);
//  var modal = new Modal(elementModal, {
//   keyboard: false,
//   backdrop: 'static',
//   focus: true,
//  });
//  openModal.addEventListener('click', function (event) {
//   event.preventDefault();
//   modal.show();
//  });
//  closeModal.forEach(function (closeEl) {
//   closeEl.addEventListener('click', function () {
//    modal.hide();
//   });
//  });
// }
