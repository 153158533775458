/*setTimeout(() => {
  const sucursalSeleccionada = sessionStorage.getItem('sucursalSeleccionada');
  console.log("SUCURSALLLLLL DE OTRA PAGE");
  console.log(sucursalSeleccionada);
  if (sucursalSeleccionada) {
    activarAnimacionIndex(sucursalSeleccionada);
    sessionStorage.removeItem('sucursalSeleccionada'); // Limpia la información después de usarla
  }
}, 5000); // 3000 milisegundos = 3 segundos

document.addEventListener('DOMContentLoaded', () => {
  // Aquí puedes agregar cualquier otro código relacionado con el DOMContentLoaded si es necesario
});

function activarAnimacionIndex(sucursal) {
  const gridItem = document.querySelector(`.portfolio-item[data-sucursal="${sucursal}"]`);

  if (gridItem) {
    // Activa la animación aquí, por ejemplo:
    gridItem.click();
    console.log(`Activando animación para la sucursal ${sucursal}`);
  } else {
    console.log(`No se encontró un grid de imágenes para la sucursal ${sucursal}`);
  }
}*/


document.addEventListener('DOMContentLoaded', () => {
	const sucursalLinks = document.querySelectorAll('#mainMenu [data-sucursal]');
	sucursalLinks.forEach(link => {
	link.addEventListener('click', e => {
	e.preventDefault();
	const sucursal = link.getAttribute('data-sucursal');
	almacenarSucursalSeleccionada(sucursal);
	//redirigirAIndex();
	});
	});
	function almacenarSucursalSeleccionada(sucursal) {
	sessionStorage.setItem('sucursalSeleccionada', sucursal);
	}
	function redirigirAIndex() {
	window.location.href = 'index.html';
	}
	setTimeout(() => {
	const sucursalSeleccionada = sessionStorage.getItem('sucursalSeleccionada');
	if (sucursalSeleccionada) {
	activarAnimacionIndex(sucursalSeleccionada);
	sessionStorage.removeItem('sucursalSeleccionada'); // Limpia la información después de usarla
	}
	}, 100); // 3000 milisegundos = 3 segundos
	document.addEventListener('DOMContentLoaded', () => {
	// Aquí puedes agregar cualquier otro código relacionado con el DOMContentLoaded si es necesario
	});
	function activarAnimacionIndex(sucursal) {
	const gridItem = document.querySelector(`.portfolio-item[data-sucursal="${sucursal}"]`);
	if (gridItem) {
	// Activa la animación aquí, por ejemplo:
	gridItem.click();
	} else {
	console.log(`No se encontró un grid de imágenes para la sucursal ${sucursal}`);
	}
	}
	});
