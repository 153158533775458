<template>
    <div class="container d-flex flex-column align-items-center mt-5 w-75" style="height: 30rem">
        <div class="d-flex justify-content-end w-100 mb-3">
            <el-dropdown v-if="true" @command="handleSucursal">
                <span class="btn btn-primary">Selecciona una sucursal <i class="el-icon-arrow-down el-icon--right"></i></span>
                <el-dropdown-menu>
                    <el-dropdown-item :key="sucursal.id" :command="sucursal" v-for="sucursal in sucursalesFilter">
                        {{ sucursal.label ? 'hola mundo' : 'NO' }}
                      </el-dropdown-item>                      
                </el-dropdown-menu>
            </el-dropdown>              
            <el-dropdown v-else @command="metodos.handleCommand">
                <span class="btn btn-success">Selecciona un día <i class="el-icon-arrow-down el-icon--right"></i></span>
                <el-dropdown-menu>
                    <el-dropdown-item :key="day.id" :command="day" v-for="day in variables.days">{{ day.name }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>              
        </div>
        <el-table :data="variables.classSwimming.filter(sucursal => sucursal.meeting_days[variables.filtroDia] == true && sucursal.location_name == variables.sucursalPage)" class="overflow-auto" style="border: 4px solid #fe7529;" empty-text="Lo sentimos, por el momento no contamos con horarios disponibles en el día seleccionado, por favor selecciona otro día." v-loading="loading">
          <el-table-column align="center" prop="description" label="Descripción">
            <template v-slot="scope">
              <div class="d-flex flex-column">
                <el-button v-if="!variables.isCalendarAtMain" type="success" size="small" icon="el-icon-thumb" class="mb-2" @click="variables.login3 = true; preloadClass(scope.row.online_reg_link, scope.row.name, scope.row.start_time, scope.row.end_time); variables.isCalendarAtMain=true; callNexTab();">RESERVAR</el-button>
                <el-tag>{{ scope.row.location_name }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Horario" align="center">
            <template v-slot="scope">
              <div class="d-flex flex-column">
                <div v-if="day">
                    <div v-for="(day, index) in scope.row.meeting_days" v-bind:key="index">
                        <div v-if="d.code == index">
                            <el-tag class="mb-2" v-for="d in variables.days" v-bind:key="d.name" type="success">{{ d.name }}</el-tag>
                        </div>
                        <span>{{ scope.row.start_time }} - {{ scope.row.end_time }}</span>
                    </div>
                </div>
                
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Instructor" align="center">
            <template v-slot="scope">{{ scope.row.instructors[0] }}</template>
          </el-table-column>
          <el-table-column label="Disponibilidad" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.openings.calculated_openings" type="info">{{ scope.row.openings.calculated_openings }}</el-tag>
              <el-tag v-else type="danger">No Disponible</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Mensualidad" align="center">
            <template v-slot="scope">
              <el-tag v-if="scope.row.tuition.fee" type="warning">$ {{ scope.row.tuition.fee }}</el-tag>
              <el-tag v-else type="danger">No Disponible</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>      
</template>
<script>
export default {
    /*data() {
        return {
            collaborator: collaboratorStore(),
            views: stepStore(),
            options: [{
            value: 'femenino',
            label: 'Femenino'
            }, {
            value: 'masculino',
            label: 'Masculino'
            }, {
            value: 'otro',
            label: 'Otro'
            }]
        };
    },
    components: {},
    created() {},
    mounted() {
    },
    methods: {
        async handleSubmit(){
            this.collaborator.store()
        }
    },*/
};
</script>