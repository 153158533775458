
/**
 * @author    ThemePunch <info@themepunch.com>
 * @link      http://www.themepunch.com/
 * @copyright 2017 ThemePunch
 */
;(function(){function g(b,a){this.calc=!1;this.slider=b;this.scrolled=!1;this.ids=b[0]&&b[0].id?b[0].id:null;this.time=a.time;this.ease=a.ease;this.color=a.color;this.point=a.point;this.height=a.height;this.onScroll=a.scroll;this.inverted=a.inverted;this.animated=a.animated;this.negative=a.negative;this.placement=a.placement;this.leftWidth=a.leftWidth;this.rightWidth=a.rightWidth;this.responsive=a.responsive;this.range="slider"===a.range;this.isTop="top"===a.position;this.starter=this.calculate.bind(this);b.one("revolution.slide.onloaded",
this.init.bind(this))}var d,e;window.RsPolyfoldAddOn=function(b,a,c){b&&a&&(d=b,e=d(window),d.event.special.polyfoldDestroyed={remove:function(a){a.handler()}},new g(a,c))};g.prototype={init:function(){if(document.body.contains(this.slider[0])){var b,a="rs-addon-polyfold",c=document.createElement("div"),d=document.createDocumentFragment();this.left=document.createElement("div");this.right=document.createElement("div");this.gridWidth=this.slider[0].opt.gridwidth;Array.isArray(this.gridWidth)&&(this.gridWidth=
this.gridWidth[0]);this.isTop?(b="rs-addon-poly-top",a+=" rs-addon-poly-top"):(b="rs-addon-poly-bottom",a+=" rs-addon-poly-bottom");1<this.placement&&(a+=" ",2===this.placement?(a+="rs-addon-poly-nav-level",this.slider.find(".tparrows").css("z-index",1E3)):a+="rs-addon-poly-static-level");if(this.animated){var f=document.createElement("style");f.type="text/css";f.innerHTML="#"+this.ids+"_wrapper ."+b+" div {transition: border-width "+this.time+"s "+this.ease+";}";document.getElementsByTagName("head")[0].appendChild(f)}"center"===
this.point&&(a+=" rs-addon-poly-center");d.appendChild(this.left);d.appendChild(this.right);c.className=a;c.appendChild(d);this.slider.one("polyfoldDestroyed",this.destroy.bind(this));this.slider[0].parentNode.insertBefore(c,this.slider.nextSibling);e.on("resize.rspolyaddon"+this.ids,this.resize.bind(this));if(this.onScroll)e.on("scroll.rspolyaddon"+this.ids,this.draw.bind(this));this.colors();this.resize(!1,!0)}else this.destroy()},colors:function(){var b,a;this.negative?this.isTop?(b="transparent "+
this.color+" transparent transparent",a=this.color+" transparent transparent transparent"):(b="transparent transparent "+this.color+" transparent",a="transparent transparent transparent "+this.color):this.isTop?(b=this.color+" transparent transparent transparent",a="transparent "+this.color+" transparent transparent"):(b="transparent transparent transparent "+this.color,a="transparent transparent "+this.color+" transparent");this.left.style.borderColor=b;this.right.style.borderColor=a},draw:function(b){var a,
c;if(this.onScroll)if(b&&(this.scrolled=!0),a=this.slider[0].getBoundingClientRect(),a.top+window.pageYOffset<this.winHeight+window.pageYOffset&&0<a.bottom)this.isTop?(b=this.range?this.winHeight-this.sliderHeight:0,this.inverted?(a=!1!==this.calc?a.top:this.left.getBoundingClientRect().top,this.calc=this.polyHeight-(a-b)/this.drawHeight*this.polyHeight):(a=!1!==this.calc?a.top+this.calc:this.left.getBoundingClientRect().top,this.calc=(a-b)/this.drawHeight*this.polyHeight)):this.inverted?(a=!1!==
this.calc?a.bottom:this.left.getBoundingClientRect().top,this.calc=this.polyHeight-(this.drawHeight-a)/this.drawHeight*this.polyHeight):(a=!1!==this.calc?a.bottom-this.calc:this.left.getBoundingClientRect().top,this.calc=(this.drawHeight-a)/this.drawHeight*this.polyHeight),this.calc=Math.floor(Math.min(Math.max(this.calc,0),this.polyHeight));else return 1;else this.calc=this.polyHeight;this.negative?this.isTop?(b="0 "+Math.ceil(this.drawWidth*this.leftWidth)+"px "+this.calc+"px 0",c=this.calc+"px "+
Math.ceil(this.drawWidth*this.rightWidth)+"px 0 0"):(b="0 0 "+this.calc+"px "+Math.ceil(this.drawWidth*this.leftWidth)+"px",c=this.calc+"px 0 0 "+Math.ceil(this.drawWidth*this.rightWidth)+"px"):this.isTop?(b=this.calc+"px "+Math.ceil(this.drawWidth*this.leftWidth)+"px 0 0",c="0 "+Math.ceil(this.drawWidth*this.rightWidth)+"px "+this.calc+"px 0"):(b=this.calc+"px 0 0 "+Math.ceil(this.drawWidth*this.leftWidth)+"px",c="0 0 "+this.calc+"px "+Math.ceil(this.drawWidth*this.rightWidth)+"px");this.left.style.borderWidth=
b;this.right.style.borderWidth=c;if(this.onScroll)return a},resize:function(b,a){this.winHeight=window.innerHeight;this.drawWidth=this.slider[0].clientWidth;this.sliderHeight=this.slider[0].clientHeight;this.drawHeight=this.range?this.sliderHeight:this.winHeight;this.polyHeight=this.responsive?Math.round(this.drawWidth/this.gridWidth*this.height):this.height;this.polyHeight=Math.min(this.polyHeight,this.sliderHeight);a&&this.onScroll?this.onScroll&&(this.oldOffset=0,this.newOffset=1,this.calculate()):
this.draw()},calculate:function(){this.scrolled||this.newOffset===this.oldOffset?delete this.starter:(this.oldOffset=this.newOffset,this.newOffset=this.draw(),window.requestAnimationFrame(this.starter))},destroy:function(){e.off("scroll.rspolyaddon"+this.ids+" resize.rspolyaddon"+this.ids);for(var b in this)this.hasOwnProperty(b)&&delete this[b]}}})();
