import 'revslider'; // Importa todo el módulo
import 'jquery'; // Importa el módulo jQuery

require('revslider/js/extensions/revolution.extension.actions.min.js');
require('revslider/js/extensions/revolution.extension.carousel.min.js');
require('revslider/js/extensions/revolution.extension.kenburn.min.js');
require('revslider/js/extensions/revolution.extension.layeranimation.min.js');
require('revslider/js/extensions/revolution.extension.migration.min.js');
require('revslider/js/extensions/revolution.extension.navigation.min.js');
require('revslider/js/extensions/revolution.extension.parallax.min.js');
require('revslider/js/extensions/revolution.extension.slideanims.min.js');
require('revslider/js/extensions/revolution.extension.video.min.js');
