import { variables } from "./data";

import moment from "moment";
import es from "moment/locale/es-mx";

moment.locale("es", es);

function categoryFilter(categorySelected, levelCorrespondent) {
  variables.category = categorySelected;
  variables.level = levelCorrespondent;
  if (variables.category == "MB-CE") {
    variables.classSwimming = variables.classSwimming.filter(
      (category) =>
        category.category1 == variables.category ||
        category.category1 == "MB-CC"
    );
  } else {
    variables.classSwimming = variables.classSwimming.filter(
      (category) => category.category1 == variables.category
    );
  }
  availableBranches();
}
function availableBranches() {
  variables.classSwimming.forEach((sucursal) => {
    variables.sucursales.push(sucursal.location_name);
  });
  variables.sucursales = variables.sucursales.filter(
    (sucursal, index) => variables.sucursales.indexOf(sucursal) === index
  );
}
function displayKids1() {
  variables.viewAge = false;
  variables.viewKids1 = true;
  variables.viewCategory1 = true;
  variables.question = "¿Qué tipo de clase te interesa?";
}
function displayCategory1() {
  categoryFilter("IND", "Estrella de Mar");
  variables.viewQuestion = false;
  variables.viewCategory1 = false;
  variables.viewKids1 = false;
  variables.viewLevel = true;
}
function displayAgeCategory1() {
  variables.question = "Selecciona un rango de edad:";
  variables.viewCategory1 = false;
  variables.viewAgeCategory1 = true;
}
function disabledAgeCategory1() {
  variables.viewQuestion = false;
  variables.viewAgeCategory1 = false;
  variables.viewKids1 = false;
  variables.viewLevel = true;
}
function displayKids2() {
  variables.viewAge = false;
  variables.viewKids2 = true;
  variables.question = variables.questionsKids["3-7-anos"][0].question;
}
function displayKids3() {
  variables.viewAge = false;
  variables.viewKids3 = true;
  variables.question = variables.questionsKids["8-12-anos"][0].question;
}
function displayAdults() {
  variables.viewAge = false;
  variables.viewAdults = true;
  variables.question = "Selecciona una modalidad:";
}
function displayModality1() {
  variables.viewQuestion = false;
  variables.viewAdults = false;
  variables.viewLevel = true;
}
function onChangeSelection(ageSelected, answer) {
  if (answer == "yes") {
    variables.countSelected += 1;
    if (
      variables.countSelected != variables.questionsKids[ageSelected].length
    ) {
      variables.question =
        variables.questionsKids[ageSelected][variables.countSelected].question;
    }
    if (
      variables.countSelected == variables.questionsKids[ageSelected].length
    ) {
      variables.countSelected -= 1;
      categoryFilter(
        variables.questionsKids[ageSelected][variables.countSelected].category1,
        variables.questionsKids[ageSelected][variables.countSelected].animal1
      );
      variables.countSelected == 0;
      variables.viewQuestion = false;
      variables.viewKids2 = false;
      variables.viewKids3 = false;
      variables.viewLevel = true;
    }
  } else {
    if (
      variables.countSelected ==
      variables.questionsKids[ageSelected].length - 1
    ) {
      categoryFilter(
        variables.questionsKids[ageSelected][variables.countSelected].category2,
        variables.questionsKids[ageSelected][variables.countSelected].animal2
      );
      variables.countSelected == 0;
      variables.viewQuestion = false;
      variables.viewKids2 = false;
      variables.viewKids3 = false;
      variables.viewLevel = true;
    } else {
      categoryFilter(
        variables.questionsKids[ageSelected][variables.countSelected].category,
        variables.questionsKids[ageSelected][variables.countSelected].animal
      );
      variables.countSelected == 0;
      variables.viewQuestion = false;
      variables.viewKids2 = false;
      variables.viewKids3 = false;
      variables.viewLevel = true;
    }
  }
}
function checkAge() {
  let dateCurrent = moment();
  let dateSelected = moment(variables.student.birthday, "DD/MM/YYYY");
  variables.ageRange = moment(dateCurrent).diff(moment(dateSelected), "months");
  variables.ageRange >= 0 && variables.ageRange < 3
    ? (variables.ageError = true)
    : (variables.ageError = false);
  variables.ageRange < 0
    ? (variables.ageError2 = true)
    : (variables.ageError2 = false);
}
function handleCommand(command) {
  variables.filtroDia = command.code;
}
function selectedBranch() {
  if (document.querySelector(".canada")) {
    const canada = document.querySelector(".canada");
    canada.addEventListener("click", function () {
      variables.sucursalPage = "CANADA";
    });
  }
  if (document.querySelector(".gruta")) {
    const gruta = document.querySelector(".gruta");
    gruta.addEventListener("click", function () {
      variables.sucursalPage = "GRUTA";
    });
  }
  if (document.querySelector(".del-valle")) {
    const delValle = document.querySelector(".del-valle");
    delValle.addEventListener("click", function () {
      variables.sucursalPage = "DEL VALLE";
    });
  }
  if (document.querySelector(".grand-san-francisco")) {
    const grandSanFrancisco = document.querySelector(".grand-san-francisco");
    grandSanFrancisco.addEventListener("click", function () {
      variables.sucursalPage = "GRAND SAN FRANCISCO";
    });
  }
  if (document.querySelector(".san-jeronimo")) {
    const sanJeronimo = document.querySelector(".san-jeronimo");
    sanJeronimo.addEventListener("click", function () {
      variables.sucursalPage = "SAN JERONIMO";
    });
  }
  if (document.querySelector(".garden-santa-fe")) {
    const gardenSantaFe = document.querySelector(".garden-santa-fe");
    gardenSantaFe.addEventListener("click", function () {
      variables.sucursalPage = "GARDEN SANTA FE";
    });
  }
  if (document.querySelector(".satelite")) {
    const satelite = document.querySelector(".satelite");
    satelite.addEventListener("click", function () {
      variables.sucursalPage = "SATELITE";
    });
  }
}
function selectedCategory() {
  if (document.querySelector(".mama-bebe")) {
    const mamaBebe = document.querySelectorAll(".mama-bebe");
    for (let i = 0; i < mamaBebe.length; i++) {
      mamaBebe[i].addEventListener("click", function () {
        variables.categoryPriceMB = true;
      });
    }
  }
  if (document.querySelector(".ind-bebe")) {
    const indBebe = document.querySelectorAll(".ind-bebe");
    for (let i = 0; i < indBebe.length; i++) {
      indBebe[i].addEventListener("click", function () {
        categoryFilter("IND", "Estrella de Mar");
      });
    }
  }
  if (document.querySelector(".ind-kids")) {
    const indKids = document.querySelectorAll(".ind-kids");
    for (let i = 0; i < indKids.length; i++) {
      indKids[i].addEventListener("click", function () {
        categoryFilter("IND", "Tortuga");
      });
    }
  }
  if (document.querySelector(".semi")) {
    const semi = document.querySelectorAll(".semi");
    for (let i = 0; i < semi.length; i++) {
      semi[i].addEventListener("click", function () {
        categoryFilter("SEMI-30MIN", "Pez Payaso");
      });
    }
  }
  if (document.querySelector(".gpa")) {
    const gpa = document.querySelectorAll(".gpa");
    for (let i = 0; i < gpa.length; i++) {
      gpa[i].addEventListener("click", function () {
        categoryFilter("GPA", "Foca");
      });
    }
  }
  if (document.querySelector(".ai")) {
    const adultoIndividual = document.querySelectorAll(".ai");
    for (let i = 0; i < adultoIndividual.length; i++) {
      adultoIndividual[i].addEventListener("click", function () {
        window.open("https://form.jotform.com/220234682543048");
      });
    }
  }
  if (document.querySelector(".aq")) {
    const adultosGPA = document.querySelectorAll(".aq");
    for (let i = 0; i < adultosGPA.length; i++) {
      adultosGPA[i].addEventListener("click", function () {
        variables.category = "Grupal y Aquaeróbics";
        variables.level = "Adulto Grupal";
        variables.classSwimming = variables.classSwimming.filter(
          (category) =>
            category.category1 == "GPA" || category.category1 == "AQ"
        );
      });
    }
  }
}
function selectedSingleSucursal() {
  let url = window.location.href;
  const parameters = url.split("/");
  if (parameters[4] == "canada") {
    variables.sucursalPage = "CANADA";
  } else if (parameters[4] == "santa-fe") {
    variables.sucursalPage = "GARDEN SANTA FE";
  } else if (parameters[4] == "grand-san-francisco") {
    variables.sucursalPage = "GRAND SAN FRANCISCO";
  } else if (parameters[4] == "san-jeronimo") {
    variables.sucursalPage = "SAN JERONIMO";
  } else if (parameters[4] == "gruta") {
    variables.sucursalPage = "GRUTA";
  } else if (parameters[4] == "del-valle") {
    variables.sucursalPage = "DEL VALLE";
  } else if (parameters[4] == "satelite") {
    variables.sucursalPage = "SATELITE";
  }
}
function selectedAge() {
  if (document.querySelector(".age-1")) {
    const bebes = document.querySelector(".age-1");
    bebes.addEventListener("click", function () {
      variables.viewAgeSelected1 = true;
    });
  }
  if (document.querySelector(".age-2")) {
    const ninos = document.querySelector(".age-2");
    ninos.addEventListener("click", function () {
      variables.viewAgeSelected2 = true;
    });
  }
  if (document.querySelector(".age-3")) {
    const adultos = document.querySelector(".age-3");
    adultos.addEventListener("click", function () {
      variables.viewAgeSelected3 = true;
    });
  }
}
function changeValue(value) {
  variables.classSwimming = JSON.parse(JSON.stringify(variables.classBackUp));
  value.forEach(function (val) {
    if (val != undefined) {
      variables.classSwimming = variables.classSwimming.filter(
        (typeFilter) =>
          typeFilter.category1 == val || typeFilter.location_name == val
      );
    }
  });
}

export const metodos = {
  categoryFilter,
  availableBranches,
  displayKids1,
  displayCategory1,
  displayAgeCategory1,
  disabledAgeCategory1,
  displayKids2,
  displayKids3,
  displayAdults,
  displayModality1,
  onChangeSelection,
  checkAge,
  handleCommand,
  selectedBranch,
  selectedCategory,
  selectedSingleSucursal,
  selectedAge,
  changeValue,
};
