<template>
    <div class="vue-calendar">
        <section class="mt-5" id="app-clases-calendario" >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h1 class="text-primary text-center p-2 mb-4 mt-3 bg-title rounded-pill">Calendario de Clases</h1>
                    </div>
                    <div class="col-12 col-lg-12 p-0">
                        <h2 class="text-primary text-center">Conoce la variedad de clases que tenemos para ti.</h2>
                    </div>
                    <div class="col-10 col-lg-3">
                        <img src="https://picsum.photos/200/100" alt="clases de prueba natación" loading="lazy" />
                        <a id="calendar-test" @click="modal = true;" type="button" class="btn hvr-grow-shadow btn-secondary rounded-pill text-white type-font p-2 w-100 fs-5">¡Agenda tu clase de prueba!</a>
                    </div>
                </div>
            </div>
            <el-dialog :visible="modal" style="width=80%;" center @opened="restoreData();" @closed="closeDialog();" :modal="false">
                <h2 class="text-primary text-break text-center" v-if="true" ></h2>
                <div class="container-fluid mt-5" v-if="true">
                    <div class="row justify-content-center" style="display:none;">
                        <div class="col-12 col-lg-5">
                            <button type="button" class="btn w-100 h-100 btn-info hvr-grow-shadow text-white" style="margin-top: 0 !important; margin-bottom: 0 !important;" @click="displayFormStudent();">Sí</button>
                        </div>
                        <div class="col-12 mt-3 col-lg-5 mt-lg-0"> 
                            <button type="button" class="btn w-100 btn-secondary hvr-grow-shadow text-white" @click="displayFormNewContact();">No</button>
                        </div>
                    </div>
                </div>
                <div v-if="true">
                    <!--// 'includes/wizard-exstudent.php';-->
                </div>
                <div v-if="true">
                    <!--// 'include 'includes/wizard-class.php';-->
                </div>
            </el-dialog>
            <el-dialog title="Formulario Clase Prueba" :visible="false"  width="80%" center @closed="closeDialog();" :modal="false">
                <!--'includes/wizard-class.php';-->
            </el-dialog>
               <!--'includes/tabla-calendario.php';-->
        </section>
        <TableClasses />
    </div>
</template>
<script>
import TableClasses from '../TableClasses/TableClasses.vue'
import {
  variables,
  apiEndPointLead,
  apiEndPointExStudent,
} from '../../js/includes/data';
import { metodos } from '../../js/includes/methods.js';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    TableClasses
  },
  el: '#app-clases-calendario',
    data: function () {
      return {
        loading: true,
        modal: false,
        viewExStudent: false,
        viewDialogClass: false,
        sucursalSelected: 'CANADA',
        variables: variables,
        metodos: metodos,
        sucursalesFilter: [
          {
            value: 'CANADA',
            label: 'Cañada',
          },
          {
            value: 'GARDEN SANTA FE',
            label: 'Santa Fe',
          },
          {
            value: 'GRAND SAN FRANCISCO',
            label: 'Grand San Francisco',
          },
          {
            value: 'SAN JERONIMO',
            label: 'San Jerónimo',
          },
          {
            value: 'GRUTA',
            label: 'Gruta',
          },
          {
            value: 'DEL VALLE',
            label: 'Del Valle',
          },
          {
            value: 'SATELITE',
            label: 'Satélite',
          },
        ],
      };
    },
    async created() {
      this.variables.classSwimming = await fetch(
        'https://app.jackrabbitclass.com/jr3.0/Openings/OpeningsJson?OrgId=507873&Status=Active&Sort=start_time ASC'
      )
        .then((response) => response.json())
        .then((response) => response.rows)
        .finally(() => {
          this.loading = false;
        });
      this.variables.classBackUp = JSON.parse(
        JSON.stringify(this.variables.classSwimming)
      );
    },
    methods: {
      restoreData() {
        this.variables.classSwimming = JSON.parse(
          JSON.stringify(this.variables.classBackUp)
        );
        this.variables.sucursales.length = 0;
        this.restoreTable();
      },
      closeDialog() {
        this.modal = false;
        this.viewExStudent = false;
        this.viewDialogClass = false;
        this.variables.viewQuestion = true;
        this.variables.question = '¿Has nadado en Cañada?';
        this.variables.category = '';
        this.variables.level = '';
        this.variables.countSelected = 0;
        this.variables.ageRange = null;
        this.variables.ageError = false;
        this.variables.ageError2 = false;
        this.variables.idClass = '';
        this.variables.name = '';
        this.variables.time = '';
        this.variables.viewExperience = true;
        this.variables.viewAge = false;
        this.variables.viewKids1 = false;
        this.variables.viewCategory1 = false;
        this.variables.viewAgeCategory1 = false;
        this.variables.viewKids2 = false;
        this.variables.viewKids3 = false;
        this.variables.viewAdults = false;
        this.variables.viewLevel = false;
        this.variables.viewFormStudent = false;
        this.variables.category = '';
        this.variables.level = '';
        this.variables.filtroDia = 'mon';
        this.variables.categoryPriceMB = false;
        this.variables.sendData = false;
        this.variables.family.name = '';
        this.variables.family.last_name = '';
        this.variables.family.email = '';
        this.variables.family.confirm_email = '';
        this.variables.family.phone = '';
        this.variables.student.name = '';
        this.variables.student.last_name = '';
        this.variables.student.gender = '';
        this.variables.student.birthday = '';
        this.variables.student.aviso = '';
        this.variables.exstudent.name = '';
        this.variables.exstudent.last_name = '';
        this.variables.exstudent.email = '';
        this.variables.exstudent.phone = '';
        this.variables.exstudent.level = '';
        this.variables.exstudent.place = '';
      },
      displayAge() {
        this.viewExStudent = false;
        this.variables.viewExperience = false;
        this.variables.viewAge = true;
        this.variables.question = '¿Qué edad tienes?';
      },
      selectedBranch(selectedSucursal) {
        this.variables.classSwimming = this.variables.classSwimming.filter(
          (sucursal) => sucursal.location_name == selectedSucursal
        );
        this.closeDialog();
      },
      preloadClass(link, name, startTime, endTime) {
        this.variables.name = name;
        this.variables.time = startTime + '-' + endTime;
        let searchParams = new URLSearchParams(link);
        this.variables.idClass = searchParams.get('amp;preLoadClassID');
        if (
          this.variables.login2 == true ||
          (this.variables.login3 == true &&
            this.variables.login1 != true &&
            window.location.pathname != '/clases-promociones/')
        ) {
          window.open(
            'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgId=507873'
          );
        } else if (
          this.variables.login1 == true &&
          this.variables.login3 == true
        ) {
          this.viewDialogClass = true;
        } else if (
          this.variables.login3 == true &&
          this.variables.login1 != true &&
          window.location.pathname == '/clases-promociones/'
        ) {
          this.viewDialogClass = true;
        }
      },
      displayFormAdults() {
        window.open('https://form.jotform.com/220234682543048');
        this.closeDialog();
      },
      displayExStudent() {
        this.variables.viewExperience = false;
        this.variables.question = '¿Eres alumno actual de Club Cañada?';
        this.viewExStudent = true;
      },
      restoreTable() {
        this.variables.login1 = false;
        this.variables.login2 = false;
        this.variables.login3 = false;
      },
      displayFormStudent() {
        this.variables.viewQuestion = false;
        this.variables.viewExperience = false;
        this.viewExStudent = false;
        this.variables.viewFormStudent = true;
      },
      selectedBranchSucursales() {
        this.closeDialog();
      },
      onSubmitContact() {
        this.$refs.contact.validate().then((success) => {
          if (success) {
            this.$refs.wizard.nextTab();
          }
        });
      },
      onSubmitStudent() {
        this.$refs.student.validate().then((success) => {
          if (success) {
            this.variables.sendData = true;
            this.sendDataFamily();
          }
        });
      },
      onSubmitExStudent() {
        this.$refs.exStudent.validate().then((success) => {
          if (success) {
            this.variables.sendData = true;
            this.sendDataExStudent();
          }
        });
      },
      async sendDataFamily() {
        var object = {};
        object['id_class'] = this.variables.idClass;
        object['family'] = this.variables.family;
        object['student'] = this.variables.student;
        object['info_class'] = this.variables.name + ' ' + this.variables.time;
        await axios
          .post(apiEndPointLead, object)
          .then((result) => {
            console.log(result);
            this.variables.sendData = false;
            this.$refs.wizard.nextTab();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async sendDataExStudent() {
        var object = {};
        object['name'] = this.variables.exstudent.name;
        object['last_name'] = this.variables.exstudent.last_name;
        object['email'] = this.variables.exstudent.email;
        object['phone'] = this.variables.exstudent.phone;
        object['level'] = this.variables.exstudent.level;
        object['place'] = this.variables.exstudent.place;
        await axios
          .post(apiEndPointExStudent, object)
          .then((result) => {
            console.log(result);
            this.variables.sendData = false;
            this.$refs.wizard.nextTab();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      handleSucursal(sucursal) {
        this.sucursalSelected = sucursal.value;
      },
    },
  
}
</script>