
import App from './App.vue'
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/calendar.css'

Vue.use(ElementUI);

new Vue({
    render: h => h(App)
  }).$mount('#app')
  