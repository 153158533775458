const isDebug = window.location.hostname == "dev.ccnatacion.com" ? true : false;

export const apiEndPointLead = isDebug
  ? "https://torniquete.webdecero.dev/api/lead"
  : "https://api.ccnatacion.com/api/lead";

export const apiEndPointMail = isDebug
  ? "https://torniquete.webdecero.dev/api/lead/verify/email"
  : "https://api.ccnatacion.com/api/lead/verify/email";

export const apiEndPointPhone = isDebug
  ? "https://torniquete.webdecero.dev/api/lead/verify/phone"
  : "https://api.ccnatacion.com/api/lead/verify/phone";

export const apiEndPointExStudent = isDebug
  ? "https://torniquete.webdecero.dev/api/ex-student"
  : "https://api.ccnatacion.com/api/ex-student";

export const apiEndPointMailExStudent = isDebug
  ? "https://torniquete.webdecero.dev/api/ex-student/verify/email"
  : "https://api.ccnatacion.com/api/ex-student/verify/email";

export const apiEndPointPhoneExStudent = isDebug
  ? "https://torniquete.webdecero.dev/api/ex-student/verify/phone"
  : "https://api.ccnatacion.com/api/ex-student/verify/phone";

export const variables = {
  filtroDia: "mon",
  category: "",
  level: "",
  login1: false,
  login2: false,
  login3: false,
  viewQuestion: true,
  question: "¿Has nadado en Cañada?",
  viewExperience: true,
  viewAge: false,
  viewKids1: false,
  viewCategory1: false,
  viewAgeCategory1: false,
  viewKids2: false,
  viewKids3: false,
  viewAdults: false,
  viewLevel: false,
  viewFormStudent: false,
  countSelected: 0,
  classSwimming: [],
  classBackUp: [],
  sucursales: [],
  ageRange: null,
  ageError: false,
  ageError2: false,
  sucursalPage: "",
  categoryPriceMB: false,
  viewAgeSelected1: false,
  viewAgeSelected2: false,
  viewAgeSelected3: false,
  idClass: "",
  name: "",
  time: "",
  sendData: false,
  days: [
    {
      code: "mon",
      name: "Lunes",
    },
    {
      code: "tue",
      name: "Martes",
    },
    {
      code: "wed",
      name: "Miercoles",
    },
    {
      code: "thu",
      name: "Jueves",
    },
    {
      code: "fri",
      name: "Viernes",
    },
    {
      code: "sat",
      name: "Sabado",
    },
    {
      code: "sun",
      name: "Domingo",
    },
  ],
  questionsKids: {
    "3-7-anos": [
      {
        question: "¿Tu hijo tiene experiencia nadando?",
        category: "IND",
        animal: "Tortuga",
      },
      {
        question: "¿Tu hijo se desplaza dos metros sin ayuda?",
        category: "IND",
        animal: "Tortuga",
      },
      {
        question: "¿Tu hijo nada Crol grueso?",
        category: "SEMI-30MIN",
        animal: "Pez Payaso",
      },
      {
        question: "¿Tu hijo nada Dorso grueso?",
        category: "SEMI-30MIN",
        animal: "Pez Payaso",
      },
      {
        question: "¿Tu hijo domina los estilos de Crol y Dorso de manera fina?",
        category: "FOCA",
        animal: "Foca",
      },
      {
        question: "¿Tu hijo nada pecho grueso?",
        category: "FOCA AVANZADO/DELFIN",
        animal: "Delfín",
      },
      {
        question: "¿Tu hijo nada mariposa de forma gruesa?",
        category: "DELFIN/MANTARRAYA",
        animal: "Mantarraya",
      },
      {
        question: "¿Tu hijo domina los cuatro estilos de nado?",
        category1: "MANTARRAYA/PEZ ESPADA",
        category2: "DELFIN/MANTARRAYA",
        animal1: "Pez Espada",
        animal2: "Mantarraya",
      },
    ],
    "8-12-anos": [
      {
        question: "¿Tu hijo se desplaza dos metros sin ayuda?",
        category: "IND",
        animal: "Tortuga",
      },
      {
        question: "¿Tu hijo nada Crol grueso?",
        category: "SEMI-30MIN",
        animal: "Pez Payaso",
      },
      {
        question: "¿Tu hijo nada Dorso grueso?",
        category: "SEMI-30MIN",
        animal: "Pez Payaso",
      },
      {
        question: "¿Tu hijo domina los estilos de Crol y Dorso de manera fina?",
        category: "FOCA",
        animal: "Foca",
      },
      {
        question: "¿Tu hijo nada pecho grueso?",
        category: "FOCA AVANZADO/DELFIN",
        animal: "Delfín",
      },
      {
        question: "¿Tu hijo nada mariposa de forma gruesa?",
        category: "DELFIN/MANTARRAYA",
        animal: "Mantarraya",
      },
      {
        question: "¿Tu hijo domina los cuatro estilos de nado?",
        category1: "MANTARRAYA/PEZ ESPADA",
        category2: "DELFIN/MANTARRAYA",
        animal1: "Pez Espada",
        animal2: "Mantarraya",
      },
    ],
  },
  family: {
    name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone: "",
  },
  student: {
    name: "",
    last_name: "",
    gender: "",
    birthday: "",
    aviso: "",
  },
  exstudent: {
    name: "",
    last_name: "",
    email: "",
    phone: "",
    level: "",
    place: "",
  },
  selectFilter: [
    {
      label: "3 a 36 meses",
      children: [
        {
          value: "IND",
          label: "Bebé Individual",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          label: "Mamá o papá con bebé",
          children: [
            {
              value: "MB-NG",
              label: "3 a 8 meses",
              children: [
                {
                  value: "CANADA",
                  label: "Cañada",
                },
                {
                  value: "GARDEN SANTA FE",
                  label: "Santa Fe",
                },
                {
                  value: "GRAND SAN FRANCISCO",
                  label: "Grand San Francisco",
                },
                {
                  value: "SAN JERONIMO",
                  label: "San Jerónimo",
                },
                {
                  value: "GRUTA",
                  label: "Gruta",
                },
                {
                  value: "DEL VALLE",
                  label: "Del Valle",
                },
                {
                  value: "SATELITE",
                  label: "Satélite",
                },
              ],
            },
            {
              value: "MB-G",
              label: "9 a 18 meses",
              children: [
                {
                  value: "CANADA",
                  label: "Cañada",
                },
                {
                  value: "GARDEN SANTA FE",
                  label: "Santa Fe",
                },
                {
                  value: "GRAND SAN FRANCISCO",
                  label: "Grand San Francisco",
                },
                {
                  value: "SAN JERONIMO",
                  label: "San Jerónimo",
                },
                {
                  value: "GRUTA",
                  label: "Gruta",
                },
                {
                  value: "DEL VALLE",
                  label: "Del Valle",
                },
                {
                  value: "SATELITE",
                  label: "Satélite",
                },
              ],
            },
            {
              value: "MB-CE",
              label: "19 a 36 meses",
              children: [
                {
                  value: "CANADA",
                  label: "Cañada",
                },
                {
                  value: "GARDEN SANTA FE",
                  label: "Santa Fe",
                },
                {
                  value: "GRAND SAN FRANCISCO",
                  label: "Grand San Francisco",
                },
                {
                  value: "SAN JERONIMO",
                  label: "San Jerónimo",
                },
                {
                  value: "GRUTA",
                  label: "Gruta",
                },
                {
                  value: "DEL VALLE",
                  label: "Del Valle",
                },
                {
                  value: "SATELITE",
                  label: "Satélite",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "3 a 7 años",
      children: [
        {
          value: "IND",
          label: "Tiene experiencia nadando",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "IND",
          label: "Se desplaza dos metros sin ayuda",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "SEMI-30MIN",
          label: "Nada Crol grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "SEMI-30MIN",
          label: "Nada Dorso grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "FOCA",
          label: "Domina Crol y Dorso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "FOCA AVANZADO/DELFIN",
          label: "Nada pecho grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "DELFIN/MANTARRAYA",
          label: "Nada Mariposa gruesa",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "MANTARRAYA/PEZ ESPADA",
          label: "Domina los cuatro estilos",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
      ],
    },
    {
      label: "8 a 12 años",
      children: [
        {
          value: "IND",
          label: "Se desplaza dos metros sin ayuda",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "SEMI-30MIN",
          label: "Nada Crol grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "SEMI-30MIN",
          label: "Nada Dorso grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "FOCA",
          label: "Domina Crol y Dorso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "FOCA AVANZADO/DELFIN",
          label: "Nada pecho grueso",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "DELFIN/MANTARRAYA",
          label: "Nada Mariposa gruesa",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "MANTARRAYA/PEZ ESPADA",
          label: "Domina los cuatro estilos",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
      ],
    },
    {
      label: "Más de 13 años",
      children: [
        {
          value: "AI",
          label: "Adulto Individual",
        },
        {
          value: "GPA",
          label: "Adulto Grupal",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
        {
          value: "AQ",
          label: "Aquafitness",
          children: [
            {
              value: "CANADA",
              label: "Cañada",
            },
            {
              value: "GARDEN SANTA FE",
              label: "Santa Fe",
            },
            {
              value: "GRAND SAN FRANCISCO",
              label: "Grand San Francisco",
            },
            {
              value: "SAN JERONIMO",
              label: "San Jerónimo",
            },
            {
              value: "GRUTA",
              label: "Gruta",
            },
            {
              value: "DEL VALLE",
              label: "Del Valle",
            },
            {
              value: "SATELITE",
              label: "Satélite",
            },
          ],
        },
      ],
    },
  ],
};
