import { gsap } from "gsap";

import '../../vendors/rev-slider/revolution-addons/polyfold/js/revolution.addon.polyfold.min.js';





document.addEventListener("DOMContentLoaded", function () {
    const tpj = jQuery;
    let revapi12;
    
    if (tpj("#rev_slider_12_1").revolution == undefined) {
        revslider_showDoubleJqueryError("#rev_slider_12_1");
    } else {
        revapi12 = tpj("#rev_slider_12_1").show().revolution({
            sliderType: "hero",
            jsFileLocation: "../../vendors/rev-slider/js/",
            sliderLayout: "fullwidth",
            dottedOverlay: "none",
            delay: 9000,
            navigation: {},
            responsiveLevels: [1240, 1024, 778, 480],
            visibilityLevels: [1240, 1024, 778, 480],
            gridwidth: [1240, 1024, 778, 480],
            gridheight: [868, 768, 1060, 1220],
            lazyType: "none",
            scrolleffect: {
                fade: "on",
                blur: "on",
                maxblur: "20",
                on_layers: "on",
                direction: "top",
                multiplicator_layers: "1.6",
                tilt: "10",
            },
            parallax: {
                type: "scroll",
                origo: "slidercenter",
                speed: 400,
                levels: [5, 10, 15, 20, 25, 30, 35, 40, -5, -10, -15, -20, -25, -30, -35, 55],
                disable_onmobile: "on"
            },
            shadow: 0,
            spinner: "off",
            autoHeight: "off",
            disableProgressBar: "on",
            hideThumbsOnMobile: "off",
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
                simplifyAll: "off",
                disableFocusListener: false,
            }
        });

        function reduceOpacityExceptHovered(toggle) {
            const tpSelectToggles = document.querySelectorAll('.tp-selecttoggle');
            tpSelectToggles.forEach(otherToggle => {
              if (otherToggle !== toggle) {
                gsap.to(otherToggle, 0.2, {
                  autoAlpha: 0.35,
                  ease: "power2.inOut",
                  overwrite: "auto"
                });
              }
            });
          }
      
          // Fade out not Focused Elements on Hover
          jQuery('body').on('mouseenter', '.tp-selecttoggle', function() {
            gsap.to(this, 0.2, {
              autoAlpha: 1, // Asegurarse de que el elemento con hover tenga opacidad completa al hacer hover
              ease: "power2.inOut",
              overwrite: "auto",
              onComplete: () => reduceOpacityExceptHovered(this)
            });
          });
      
          // Fade in all Elements on Blur
          jQuery('body').on('mouseleave', '.tp-selecttoggle', function() {
            const tpSelectToggles = document.querySelectorAll('.tp-selecttoggle');
            tpSelectToggles.forEach(otherToggle => {
              gsap.to(otherToggle, 0.2, {
                autoAlpha: 1,
                ease: "power2.inOut",
                overwrite: "auto"
              });
            });
          });
    }

    // Continúa con el resto de tu código aquí...
    // Por ejemplo, puedes seguir agregando más funciones y lógica específica de tu proyecto.
    // Recuerda que este es solo un fragmento del código original, y aquí puedes seguir con el resto de las operaciones que necesites realizar en tu sitio.

    RsPolyfoldAddOn(tpj, revapi12, {
        position: "top",
        color: "#ffffff",
        scroll: true,
        height: 150,
        range: "slider",
        point: "center",
        placement: 1,
        responsive: true,
        negative: true,
        leftWidth: 0.35,
        rightWidth: 0.35,
        inverted: false,
        animated: false
    });
    RsPolyfoldAddOn(tpj, revapi12, {
        position: "bottom",
        color: "#ffffff",
        scroll: true,
        height: 150,
        range: "slider",
        point: "center",
        placement: 1,
        responsive: true,
        negative: true,
        leftWidth: 0.35,
        rightWidth: 0.35,
        inverted: false,
        animated: false
    });
});
