import Swiper, { Navigation, Pagination } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
  var swiperContainer = document.querySelector('.swiper-container');
  
  if (swiperContainer) {
    var swiperdos = new Swiper(swiperContainer, {
      direction: 'vertical',
      slidesPerView: 1,
      spaceBetween: 0,
      initialSlide: 0,  // Cambia esto al índice de la diapositiva inicial que desees
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
    });

    // Capturar el evento de desplazamiento
    document.addEventListener('wheel', function(event) {
      // Evitar el desplazamiento de la página por defecto
      event.preventDefault();

      // Determinar la dirección del desplazamiento (up o down)
      var direction = event.deltaY > 0 ? 'next' : 'prev';

      // Cambiar la diapositiva en la dirección correspondiente
      if (direction === 'next') {
        swiperdos.slideNext();
      } else {
        swiperdos.slidePrev();
      }
    });
  }
});

/*
// Obtén todos los elementos contenedores con la clase '.grid__item'
var gridItems = document.querySelectorAll('.vertical-swiper-custom.text');

// Recorre los elementos contenedores
gridItems.forEach(function(item) {
    // Encuentra el elemento con la clase '.grid__item-inner' dentro del contenedor actual
    var tittle = item.querySelector('.grid__item-inner');
    var description = item.querySelector('.grid__item-inner');

    // Obtiene el color de fondo en formato RGB
    var backgroundColor = getComputedStyle(backgroundElement).backgroundColor;
    var rgbArray = backgroundColor.match(/\d+/g).map(Number);

    // Calcula el brillo del fondo
    var brightness = calculateBrightness(rgbArray);

    // Encuentra los elementos <p> con la clase '.description' dentro del contenedor actual
    var descriptionElements = item.querySelectorAll('.description p');

    // Recorre los elementos <p> y aplica la lógica de ajuste de color
    descriptionElements.forEach(function(element) {
        if (brightness > 128) { // Valor de umbral, ajusta según tu preferencia
            element.style.color = 'black';
        } else {
            element.style.color = 'white';
        }
    });
});
*/

//Swiper vertical convenios



/*
var swiperSlides = new Swiper('#swiper-vertical-convenios', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  modules: [Navigation, Pagination],
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});*/

//Swiper home card 3D
var swiper = new Swiper(".case-slider-3d-active", {
  slidesPerView: 2,
  spaceBetween: 30,
  centeredSlides: true,
  autoplay: {
    delay: 7000,
  },
  loop: true,
  grabCursor: true,
  navigation: {
    nextEl: ".case-slider-3d-active .swiper-button-next",
    prevEl: ".case-slider-3d-active .swiper-button-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    992: {
      slidesPerView: 1,
    },
  },
});


//Swiper vertical convenios
//var swiperSlides = new Swiper('.swiper-container', {
 // slidesPerView: 1,
 // spaceBetween: 10,
 // pagination: {
 //   el: '.swiper-pagination',
   // clickable: true
  //}
//});




 

// Carrusel Single Sucursal //
var swiperSingleSucursal = new Swiper('.swiper-single-sucursal', {
 loop: true,
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
 breakpoints: {
  0: {
   slidesPerView: 1,
   spaceBetween: 30,
  },
  768: {
   slidesPerView: 2,
   spaceBetween: 30,
  },
 },
});

// Carrusel Sucursales//
var swiperSucursales = new Swiper('.swiper-sucursales', {
 slidesPerView: 1,
 loop: true,
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
});

// Carrusel Clases de Natación - Acordeón //
var swiperClases = new Swiper('.swiper-clases', {
 observer: true,
 observeParents: true,
 slidesPerView: 1,
 loop: true,
 grabCursor: true,
 pagination: {
  el: '.swiper-pagination',
  clickable: true,
 },
});
var swiperIframes = new Swiper('.swiper-iframes', {
 observer: true,
 observeParents: true,
 slidesPerView: 1,
 loop: true,
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
});

// Carrusel Clases de Natación - Niveles //
// Carrusel Conocenos //
var swiperNiveles = new Swiper('.swiper-niveles', {
 loop: true,
 pagination: {
  el: '.swiper-pagination',
  type: 'bullets',
 },
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
 breakpoints: {
  0: {
   slidesPerView: 1,
  },
  768: {
   slidesPerView: 2,
   spaceBetween: 15,
  },
 },
});

// Carrusel Conocenos //
var swiperConocenos = new Swiper('.swiper-conocenos', {
 slidesPerView: 1,
 loop: true,
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
});

// Carrusel Pagina Principal Blog //
var swiperBlog = new Swiper('.swiper-blog', {
 loop: true,
 pagination: {
  el: '.swiper-pagination',
  type: 'progressbar',
 },
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
 breakpoints: {
  0: {
   slidesPerView: 1,
  },
  768: {
   slidesPerView: 2,
   spaceBetween: 15,
  },
  992: {
   slidesPerView: 3,
   spaceBetween: 15,
  },
 },
});

// Carrusel Pagina Principal Testimoniales //
var swiperTestimoniales = new Swiper('.swiper-testimoniales', {
 loop: true,
 pagination: {
  el: '.swiper-pagination',
  type: 'progressbar',
 },
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
 breakpoints: {
  0: {
   slidesPerView: 1,
  },
  768: {
   slidesPerView: 2,
   spaceBetween: 15,
  },
  992: {
   slidesPerView: 3,
   spaceBetween: 15,
  },
 },
});

// Carrusel Conocenos mobile//
var swiperConocenosMobile = new Swiper('.swiper-mobile', {
 slidesPerView: 1,
 loop: true,
 navigation: {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
 },
});
